import React from "react";
import "./css/Navbar.css";
import { NavLink } from "react-router-dom";
import { Desktop, Mobile, Tablet } from "../View/View";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import LogoPng from "./img/logo-png.png";
import ebrochure from "./img/SPC - Brochure.pdf";

const Navbar = () => {
  const Links = () => {
    return (
      <>
        <NavLink to="/" className="mt-3">
          Home
        </NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/services">Services</NavLink>
        <NavLink to="/branches">Branches</NavLink>
        <NavLink to="/gallery">Gallery</NavLink>
        <NavLink to="/contact">Contact</NavLink>
        <a
          href={ebrochure}
          rel="noreferrer"
          target="_blank"
          className="btn m-0"
        >
          E BROCHURE
        </a>
      </>
    );
  };

  const Lg = () => {
    return (
      <div className="top_nav_lg">
        <div className="container-fluid">
          <img src={LogoPng} alt="logo" className="img-flud" />
          <div className="nav_links">
            <Links />
          </div>
        </div>
      </div>
    );
  };

  const Sm = () => {
    const [show, setShow] = React.useState(false);
    return (
      <div className="top_nav_sm">
        <div className="content">
          <img src={LogoPng} alt="logo" className="img-fluid" />
          <span onClick={() => (show ? setShow(false) : setShow(true))}>
            <HiOutlineMenuAlt3 />
          </span>
        </div>
        <div className={`links ${show ? "active" : ""}`}>
          <Links />
        </div>
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <Lg />
      </Desktop>
      <Tablet>
        <Sm />
      </Tablet>
      <Mobile>
        <Sm />
      </Mobile>
    </>
  );
};

export default Navbar;
