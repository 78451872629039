import React from "react";
import "./css/Footer.css";
import { NavLink } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import alphax from "./img/alphax.png";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="content">
                <h2>About us</h2>
                <p>
                  We are started 2014, and since then have consistently strived
                  to bring the world's best in pest control solutions to your
                  very doorstep. Our customers consider us as one of the leading
                  pest control contractors in tamilnadu. We have our strong
                  presence around the nation and are widely appreciated for our
                  effective services, which are in line with the customers’
                  requirements.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-3 mt-3 mt-md-0">
              <div className="r2">
                <h2>Quick Links</h2>
                <div className="content">
                  <div className="row">
                    <div className="col-6">
                      <NavLink to="/">
                        <IoIosArrowForward />
                        Home
                      </NavLink>
                    </div>
                    <div className="col-6">
                      <NavLink to="/about">
                        <IoIosArrowForward />
                        About
                      </NavLink>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <NavLink to="/services">
                        <IoIosArrowForward />
                        Services
                      </NavLink>
                    </div>
                    <div className="col-6">
                      <NavLink to="/branches">
                        <IoIosArrowForward />
                        Branches
                      </NavLink>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <NavLink to="/gallery">
                        <IoIosArrowForward />
                        Gallery
                      </NavLink>
                    </div>
                    <div className="col-6">
                      <NavLink to="/contact">
                        <IoIosArrowForward />
                        Contact
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 mt-4 mt-md-0">
              <div className="r3">
                <h2>Main Branches</h2>
                <div className="content">
                  <h6 className="text-start">
                    2F/1497 Ashirvad Nagar, P&T Colony Extn., Behind Petchi
                    Amman Kovil, <br />
                    Tuticorin - 628 008.
                    <br /> <br />
                    Plot.9, Sri Sai Nivas, Nila Nagar, GST Road, Pasumalai,
                    <br />
                    Madurai-625 004
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="box container shadow rounded">
        <div className="row">
          <div className="col-12 col-lg-9">
            <h4>Have Any Question!</h4>
            <h3>DON'T HESITATE TO CONTACT US ANY TIME.</h3>
          </div>
          <div className="col-12 col-lg-3">
            <NavLink to="/contact">Contact us</NavLink>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center container py-4 g-4">
        <p className="m-0 fw-bold">
          Designed &amp; Scripted by
          <a href="mailto:alfhasscript@gmail.com" className="d-inline ms-1">
            Alphax Infotech
          </a>
          . All Rights Reserved.
        </p>
        <a href="mailto:alphaxinfotech@gmail.com">
          <img src={alphax} alt="logo" className="img-fluid brand" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
