import React from "react";
import Navbar from "../Components/Navbar";
import Banner from "../Components/Banner";
import Title from "../Components/Title";
import Serviceinfo from "../Components/ServiceInfo";
import Img1 from "../Components/img/service-1.jpg";
import Img2 from "../Components/img/service-2.jpg";
import Img3 from "../Components/img/service-3.jpg";
import Img4 from "../Components/img/service-4.jpg";
import Img5 from "../Components/img/service-5.jpg";
import Img6 from "../Components/img/service-6.jpg";
import Img7 from "../Components/img/service-7.jpg";
import Img8 from "../Components/img/service-8.jpg";
import Img9 from "../Components/img/service-9.jpg";
import Img10 from "../Components/img/service-10.jpg";
import Img11 from "../Components/img/service-11.jpg";
import Reviews from "../Components/Reviews";
import Footer from "../Components/Footer";

const Servicespage = () => {
  return (
    <div className="services_page">
      <Navbar />
      <Banner content="Our Services" />
      <div className="container mb-4">
        <Title
          title="Services"
          subTitle="Our Management Solutions and Services"
        />
        <div className="row">
          <Serviceinfo Img={Img1} Name="Termite Management Solutions" />
          <Serviceinfo Img={Img2} Name="Rodents Management Solutions" />
          <Serviceinfo Img={Img3} Name="Fly Management Solutions" />
          <Serviceinfo Img={Img4} Name="Mosquito Management Solutions" />
          <Serviceinfo Img={Img5} Name="Bed Bug Management Solutions" />
          <Serviceinfo Img={Img6} Name="Cockroach Management Solutions" />
          <Serviceinfo Img={Img7} Name="Lizard management Solutions" />
          <Serviceinfo Img={Img8} Name="Spider Management Solutions" />
          <Serviceinfo Img={Img9} Name="Bird Control Service" />
          <Serviceinfo Img={Img10} Name="Fumigation Service" />
          <Serviceinfo Img={Img11} Name="Snake Control Service" />
        </div>
      </div>
      <Reviews />
      <Footer />
    </div>
  );
};

export default Servicespage;
