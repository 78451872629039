import React, { useState } from "react";
import "./css/Contact.css";
import Title from "./Title";
import { BsArrowRight } from "react-icons/bs";
import { Desktop, Tablet, Mobile } from "../View/View";
import axios from "axios";

const Contact = () => {
  const Container = ({ view }) => {
    const [contact, setContact] = useState({
      name: "",
      mobile: "",
      email: "",
      location: "",
      msg: "",
    });

    const handleSubmit = (e) => {
      e.preventDefault();
      const MailAPI = "http://saipestcontrol.co.in/mail/mail.php";
      if (contact.mobile.length === 10) {
        axios
          .post(MailAPI, contact, {
            headers: { "content-type": "application/json" },
          })
          .then((res) => {
            if (res.data === 1) {
              alert("Email Was Successfully Sended !");
              setContact({
                name: "",
                mobile: "",
                email: "",
                location: "",
                msg: "",
              });
            } else {
              alert("Please Try Again");
            }
          })
          .catch((err) => console.error(err));
      } else {
        alert("Invaild Mobile Number.");
      }
    };

    const handleReset = (e) => {
      setContact({ ...contact, [e.target.name]: e.target.value });
    };

    return (
      <div className={`contact ${view}`}>
        <div className="container">
          <Title title="Contact" subTitle="Get in Touch. We're Here to Help." />
          <div className="map">
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126179.2185576506!2d78.07597686942202!3d8.776607344406944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b03ee67b4ad764f%3A0x2443e6dc90ee7d3!2sThoothukudi%2C+Tamil+Nadu!5e0!3m2!1sen!2sin!4v1479385541696"></iframe>
          </div>
          <div className="contact_form">
            <h5>
              We look forward to helping you create and maintain a clean,
              healthy environment that’s as enjoyable as it is functional.
            </h5>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control my-3"
                  placeholder="Name"
                  required
                  name="name"
                  value={contact.name}
                  onChange={handleReset}
                />
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Mobile"
                      required
                      name="mobile"
                      value={contact.mobile}
                      onChange={handleReset}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group mt-3 mt-lg-0">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      required
                      name="email"
                      value={contact.email}
                      onChange={handleReset}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control mt-3"
                  placeholder="Location"
                  required
                  name="location"
                  value={contact.location}
                  onChange={handleReset}
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control mt-3"
                  placeholder="Message"
                  required
                  name="msg"
                  value={contact.msg}
                  onChange={handleReset}
                />
              </div>
              <div className="text-center">
                <button type="submit">
                  SEND YOUR MESSAGE
                  <BsArrowRight className="ms-3" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <Container view="" />
      </Desktop>
      <Tablet>
        <Container view="sm" />
      </Tablet>
      <Mobile>
        <Container view="sm" />
      </Mobile>
    </>
  );
};

export default Contact;
