import React from "react";
import "./css/GalleryInfo.css";

const Galleryinfo = ({ Img, Name }) => {
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="photo">
        <img src={Img} alt="Gallery" className="img-fluid" />
        <h5>{Name}</h5>
      </div>
    </div>
  );
};

export default Galleryinfo;
