import React from "react";
import CitiesSlider from "./CiiesSlider";
import Slider1 from "./img/slide-1.jpg";
import Slider2 from "./img/slide-2.jpg";
import Slider3 from "./img/slide-3.jpg";
import GroupPhoto from "./img/groupPhoto.JPG";
import "./css/Home.css";
import { Desktop, Tablet, Mobile } from "../View/View";

const Home = () => {
  const slides = [
    {
      img: GroupPhoto,
      title: "SAI PEST CONTROL",
      sub_title: "Welcome to",
      btn: "GET YOUR FREE QUOTA",
      link: "/contact",
    },
    {
      img: Slider1,
      title: "COST EFFECTIVE SOLUTIONS",
      btn: "GET A SERVICES",
      link: "/contact",
    },
    {
      img: Slider2,
      title: "EXPERT IN PEST CONTROL",
      btn: "FIND A LOCATION",
      link: "/contact",
    },

    {
      img: Slider3,
      title: "PROTECT YOUR HOME TODAY",
      btn: "SCHEDULE SERVICES",
      link: "/contact",
    },
  ];

  const Container = ({ view }) => {
    return (
      <div className={`home ${view}`}>
        <CitiesSlider slides={slides} />
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <Container view="" />
      </Desktop>
      <Tablet>
        <Container view="sm" />
      </Tablet>
      <Mobile>
        <Container view="sm" />
      </Mobile>
    </>
  );
};

export default Home;
