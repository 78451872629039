import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/Service.css";
import Title from "./Title";
import { Desktop, Tablet, Mobile } from "../View/View";
import service_1 from "./img/service-1.jpg";
import service_3 from "./img/service-3.jpg";
import service_4 from "./img/service-4.jpg";
import service_5 from "./img/service-5.jpg";
import { NavLink } from "react-router-dom";
import servicebg from "./img/service.png";
import Serviceinfo from "./ServiceInfo";

const Service = () => {
  const Container = ({ view }) => {
    return (
      <div className={`services ${view}`}>
        <Title
          title="Our Services"
          subTitle="The Importance of Disinfecting and Sanitizing"
        />
        <div className="container">
          {view === "sm" ? (
            ""
          ) : (
            <>
              <div className="bg-rotate">
                <img src={servicebg} alt="service" className="img-fluid" />
              </div>
              <NavLink to="/services" className="view_service">
                View all Services
              </NavLink>
            </>
          )}
          <div className="row">
            <Serviceinfo Img={service_1} Name="Termite Management Solutions" />
            <div className="col-12 col-lg-4"></div>
            <Serviceinfo
              Img={service_3}
              Name="Fly Management Solutions"
              className="order-first order-lg-0"
            />
          </div>
          <div className="row">
            <Serviceinfo Img={service_4} Name="Mosquito Management Solutions" />
            <div className="col-12 col-lg-4"></div>
            <Serviceinfo
              Img={service_5}
              Name="Bed Bug Management Solutions"
              className="order-first order-lg-0"
            />
          </div>
          {view === "sm" ? (
            <>
              <div className="bg-rotate">
                <img src={servicebg} alt="service" className="img-fluid" />
                <NavLink to="/" className="view_service">
                  View all Services
                </NavLink>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <Container view=" " />
      </Desktop>
      <Tablet>
        <Container view="sm" />
      </Tablet>
      <Mobile>
        <Container view="sm" />
      </Mobile>
    </>
  );
};

export default Service;
