import React from "react";
import "./css/Pledge.css";
import { Desktop, Tablet, Mobile } from "../View/View";
import Title from "./Title";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pledge1 from "./img/pledge1.jpg";
import Pledge2 from "./img/pledge2.jpg";
import Pledge3 from "./img/pledge3.jpg";
import { FaTag } from "react-icons/fa";

const Pledge = () => {
  const Container = ({ view }) => {
    var settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    return (
      <div className={`pledge ${view}`}>
        <div className="container">
          <Title title="Pledge" subTitle="OUR PLEDGE TO CUSTOMER" />
        </div>
        <div className="row mt-5">
          <div className="col-12 col-lg-6">
            <Slider {...settings}>
              <div className="pledge_slider">
                <img src={Pledge1} alt="PledgeImage" className="img-fluid" />
              </div>
              <div className="pledge_slider">
                <img src={Pledge2} alt="PledgeImage" className="img-fluid" />
              </div>
              <div className="pledge_slider">
                <img src={Pledge3} alt="PledgeImage" className="img-fluid" />
              </div>
            </Slider>
          </div>
          <div className="col-12 col-lg-6 position-relative">
            <div className="content">
              <p>
                <FaTag className="me-2" />
                Sai Pest Control (SPC) will provide with the most professional,
                efficient and cost-effective Pest Management services to our
                customers.
              </p>
              <p>
                <FaTag className="me-2" />
                Our methods will follows multiple Govt. compliance and all
                regularity standards such as FSSAI, HACCP, AIB, USDA, FDA, BRC
                and CIB Standards. It is our goal to establish Customer
                Satisfaction as the principle objective while performing our
                services.
              </p>
              <p className="m-0">
                <FaTag className="me-2" />
                We are committed to providing our customer with nothing less
                than the very best quality and reliable services at an
                affordable cost. And above all, honesty and integrity is our
                only policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <Container view="" />
      </Desktop>
      <Tablet>
        <Container view="sm md" />
      </Tablet>
      <Mobile>
        <Container view="sm" />
      </Mobile>
    </>
  );
};

export default Pledge;
