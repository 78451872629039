import React from "react";
import Navbar from "../Components/Navbar";
import Banner from "../Components/Banner";
import Title from "../Components/Title";
import Galleryinfo from "../Components/GalleryInfo";
import Gal1 from "../Components/img/gallery/gallery8.jpg";
import Gal2 from "../Components/img/gallery/gallery1.jpg";
import Gal3 from "../Components/img/gallery/gallery2.jpg";
import Gal4 from "../Components/img/gallery/gallery3.jpg";
import Gal5 from "../Components/img/gallery/gallery4.jpg";
import Gal6 from "../Components/img/gallery/gallery5.jpg";
import Gal7 from "../Components/img/gallery/gallery6.jpg";
import Gal8 from "../Components/img/gallery/gallery7.jpg";
import Gal9 from "../Components/img/gallery/gallery10.jpg";
import Gal10 from "../Components/img/gallery/gallery9.jpg";
import Gal11 from "../Components/img/gallery/gallery11.jpg";
import Gal12 from "../Components/img/gallery/gallery12.jpg";
import Gal13 from "../Components/img/gallery/gallery13.jpg";
import Gal14 from "../Components/img/gallery/gallery14.jpg";
import Gal15 from "../Components/img/gallery/gallery15.jpg";
import Gal16 from "../Components/img/gallery/gallery16.jpg";
import Reviews from "../Components/Reviews";
import Footer from "../Components/Footer";

const Gallerypage = () => {
  return (
    <div>
      <Navbar />
      <Banner content="Our Gallery" />
      <div className="container mb-4">
        <Title title="Gallery" subTitle="" />
        <div className="row">
          <Galleryinfo Img={Gal1} Name="PMP Meet @ Bangalore" />
          <Galleryinfo Img={Gal2} Name="Ayuthapooja - 2018" />
          <Galleryinfo Img={Gal3} Name="PMP Meet @ Bangalore" />
          <Galleryinfo Img={Gal4} Name="RMS - Internal" />
          <Galleryinfo Img={Gal5} Name="TMS - Post Construction" />
          <Galleryinfo Img={Gal6} Name="Bulk Fumigation" />
          <Galleryinfo Img={Gal7} Name="Bulk Fumigation" />
          <Galleryinfo Img={Gal8} Name="MMS - Thermal Fogging" />
          <Galleryinfo Img={Gal9} Name="Snake Away Repellent Service" />
          <Galleryinfo Img={Gal10} Name="RMS Internal" />
          <Galleryinfo Img={Gal11} Name="Rodent Bait & Trap Station" />
          <Galleryinfo Img={Gal12} Name="IPCA" />
          <Galleryinfo Img={Gal13} Name="TMS - Post Construction" />
          <Galleryinfo Img={Gal14} Name="TMS - Pre Construction" />
          <Galleryinfo Img={Gal15} Name="IPCA Meet - 2018" />
          <Galleryinfo Img={Gal16} Name="Concrete Bin Cleaning" />
        </div>
      </div>
      <Reviews />
      <Footer />
    </div>
  );
};

export default Gallerypage;
