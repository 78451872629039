import React from "react";
import { NavLink } from "react-router-dom";
import "../Components/css/ServiceInfo.css";
import { Desktop, Tablet, Mobile } from "../View/View";

const Serviceinfo = ({ Img, Name, className }) => {
  const Container = ({ view }) => {
    return (
      <div className={`col-12 col-md-6 col-lg-4 ${className ? className : ""}`}>
        <div className={`service ${view}`}>
          <img src={Img} alt="logo" className="img-fluid" />
          <div className="info">
            <h4>{Name}</h4>
            <NavLink to="/contact">Get a Service</NavLink>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <Container view="" />
      </Desktop>
      <Tablet>
        <Container view="sm" />
      </Tablet>
      <Mobile>
        <Container view="sm" />
      </Mobile>
    </>
  );
};

export default Serviceinfo;
