import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "../Layouts/App";
import AboutPage from "../Layouts/AboutPage";
import Servicespage from "../Layouts/ServicesPage";
import Branchespage from "../Layouts/BranchesPage";
import Gallerypage from "../Layouts/GalleryPage";
import Contactpage from "../Layouts/ContactPage";
import { Navigate } from "react-router";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<Servicespage />} />
        <Route path="/branches" element={<Branchespage />} />
        <Route path="/gallery" element={<Gallerypage />} />
        <Route path="/contact" element={<Contactpage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
