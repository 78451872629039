import React from "react";
import Title from "./Title";
import "./css/Review.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Desktop, Tablet, Mobile } from "../View/View";
import cert1 from "./img/cert/cert1.jpg";
import cert2 from "./img/cert/cert2.jpg";
import cert3 from "./img/cert/cert3.jpg";
import cert4 from "./img/cert/cert4.jpg";
import cert5 from "./img/cert/cert5.jpg";
import cert6 from "./img/cert/cert6.jpg";

const Reviews = () => {
  const Container = ({ view }) => {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className={`reviews container ${view}`}>
        <Title
          title="Our Testimonials"
          subTitle="Excellent Service and Cleaning Results"
        />
        <div className={`review_slider`}>
          <Slider {...settings}>
            <div className="py-3">
              <div className="review shadow">
                <img src={cert1} alt="Certificate Pic" />
              </div>
            </div>
            <div className="py-3">
              <div className="review shadow">
                <img src={cert2} alt="Certificate Pic" />
              </div>
            </div>
            <div className="py-3">
              <div className="review shadow">
                <img src={cert3} alt="Certificate Pic" />
              </div>
            </div>
            <div className="py-3">
              <div className="review shadow">
                <img src={cert4} alt="Certificate Pic" />
              </div>
            </div>
            <div className="py-3">
              <div className="review shadow">
                <img src={cert5} alt="Certificate Pic" />
              </div>
            </div>
            <div className="py-3">
              <div className="review shadow">
                <img src={cert6} alt="Certificate Pic" />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <Container view="" />
      </Desktop>
      <Tablet>
        <Container view="sm" />
      </Tablet>
      <Mobile>
        <Container view="sm" />
      </Mobile>
    </>
  );
};

export default Reviews;
