import React from "react";
import Title from "./Title";
import "./css/Branches.css";
import { BsTelephoneFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { Desktop, Tablet, Mobile } from "../View/View";

const Branches = () => {
  const Container = ({ view }) => {
    return (
      <div className={`branches ${view}`}>
        <div className="container">
          <Title
            title="Our Branches"
            subTitle="Pest Control Company in Southern Part of Tamil Nadu"
          />
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <div className="branch">
                <h4>Madurai (Main Branch)</h4>
                <h5 className="no">
                  <span className="me-3">
                    <BsTelephoneFill />
                  </span>
                  +91 96777 26524
                </h5>
                <h5 className="mb-3">
                  <span className="me-3">
                    <MdLocationOn />
                  </span>
                  <span className="no">1</span>-A, Duraisamy Nagar,
                  <br />
                  First Floor, Gayathiri Street,
                  <br />
                  Madurai - <span className="no">625 016</span>
                </h5>
                <a href="tel:+919677726524">Request Call</a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <div className="branch">
                <h4>Tuticorin (Main Branch)</h4>
                <h5 className="no">
                  <span className="me-3">
                    <BsTelephoneFill />
                  </span>
                  +91 96777 59322
                </h5>
                <h5 className="mb-3">
                  <span className="me-3">
                    <MdLocationOn />
                  </span>
                  <span className="no">2F/1497</span> Ashirvad Nagar,
                  <br />
                  P&T Colony Extn., <br />
                  Behind Petchi Amman Kovil
                  <br />
                  Tuticorin - <span className="no">628 008.</span>
                </h5>
                <a href="tel:+919677759322">Request Call</a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <div className="branch">
                <h4>Trichy</h4>
                <h5 className="no">
                  <span className="me-3">
                    <BsTelephoneFill />
                  </span>
                  +91 96774 44673
                </h5>
                <h5 className="mb-3">
                  <span className="me-3">
                    <MdLocationOn />
                  </span>
                  Sri Jothi Complex , Arunagiri Nagar ,<br />
                  Vayalur Main Road,Konarchathiram,
                  <br />
                  Somaresampettai - Po ,<br />
                  Trichy - <span className="no">620 102</span>
                </h5>
                <a href="tel:+919677444673">Request Call</a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <div className="branch">
                <h4>Karur</h4>
                <h5 className="no">
                  <span className="me-3">
                    <BsTelephoneFill />
                  </span>
                  +91 96777 26533
                </h5>
                <h5 className="mb-3">
                  <span className="me-3">
                    <MdLocationOn />
                  </span>
                  R.R Complex , <span className="no">76</span>-E , <br />
                  New Salem Bye Pass Road,
                  <br /> Karur - <span className="no">639 002</span>
                </h5>
                <a href="tel:+919677726533">Request Call</a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <div className="branch">
                <h4>Dindigul</h4>
                <h5 className="no">
                  <span className="me-3">
                    <BsTelephoneFill />
                  </span>
                  +91 96777 59328
                </h5>
                <h5 className="mb-3">
                  <span className="me-3">
                    <MdLocationOn />
                  </span>
                  <span className="no">28</span>, Roja 3rd Cross Street,PWD
                  Colony, <br />
                  Trichy Bye-Pass, Seelapadi
                  <br />
                  Dindigul - <span className="no">624 005</span>
                </h5>
                <a href="tel:+919677759328">Request Call</a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <div className="branch">
                <h4>Rajapalayam</h4>
                <h5 className="no">
                  <span className="me-3">
                    <BsTelephoneFill />
                  </span>
                  +91 96777 19021
                </h5>
                <h5 className="mb-3">
                  <span className="me-3">
                    <MdLocationOn />
                  </span>
                  <span className="no">38/697</span>, Tiruvananthapuram Road,
                  <br />
                  Trichy Bye-Pass, Seelapadi
                  <br />
                  Rajapalayam - <span className="no">626 117</span>
                </h5>
                <a href="tel:+919677719021">Request Call</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <Container view="" />
      </Desktop>
      <Tablet>
        <Container view="sm" />
      </Tablet>
      <Mobile>
        <Container view="sm" />
      </Mobile>
    </>
  );
};

export default Branches;
