import React from "react";
import Navbar from "../Components/Navbar";
import Banner from "../Components/Banner";
import Branches from "../Components/Branches";
import Reviews from "../Components/Reviews";
import Footer from "../Components/Footer";

const Branchespage = () => {
  return (
    <div>
      <Navbar />
      <Banner content="Our Branches" />
      <Branches />
      <Reviews />
      <Footer />
    </div>
  );
};

export default Branchespage;
