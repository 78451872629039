import React from "react";
import Navbar from "../Components/Navbar";
import About from "../Components/About";
import Title from "../Components/Title";
import Bai from "../Components/img/BAI-Logo.jpg";
import Ipca from "../Components/img/IPCA-Logo.jpg";
import Banner from "../Components/Banner";
import Footer from "../Components/Footer";
import Reviews from "../Components/Reviews";

const AboutPage = () => {
  return (
    <div className="about_page">
      <Navbar />
      <Banner content="About Us" />
      <About />
      <div className="certification container pb-2 mb-4">
        <Title title="Our Certification" subTitle="" />
        <img src={Bai} alt="logo" className="img-fluid" />
        <img src={Ipca} alt="logo" className="img-fluid ms-0 ms-md-5" />
      </div>
      <Reviews />
      <Footer />
    </div>
  );
};

export default AboutPage;
