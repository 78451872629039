import React from "react";
import "./css/Banner.css";

const Banner = ({ content }) => {
  return (
    <div className="banner">
      <h1>{content}</h1>
    </div>
  );
};

export default Banner;
