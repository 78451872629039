import React from "react";
import About from "../Components/About";
import Home from "../Components/Home";
import Pledge from "../Components/Pledge";
import Navbar from "../Components/Navbar";
import Service from "../Components/Service";
import Branches from "../Components/Branches";
import Gallery from "../Components/Gallery";
import Contact from "../Components/Contact";
import Reviews from "../Components/Reviews";
import Footer from "../Components/Footer";

const App = () => {
  return (
    <>
      <Navbar />
      <Home />
      <About />
      <Pledge />
      <Service />
      <Branches />
      <Reviews />
      <Gallery />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
