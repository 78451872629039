import React from "react";
import Title from "./Title";
import "./css/Gallery.css";
import Gal1 from "./img/gallery/gallery8.jpg";
import Gal2 from "./img/gallery/gallery1.jpg";
import Gal3 from "./img/gallery/gallery2.jpg";
import Gal4 from "./img/gallery/gallery3.jpg";
import Gal5 from "./img/gallery/gallery4.jpg";
import Gal6 from "./img/gallery/gallery5.jpg";
import { NavLink } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import Galleryinfo from "./GalleryInfo";

const Gallery = () => {
  return (
    <div className="gallery">
      <div className="container">
        <Title title="Gallery" subTitle="" />
        <div className="row">
          <Galleryinfo Img={Gal1} Name="PMP Meet @ Bangalore" />
          <Galleryinfo Img={Gal2} Name="Ayuthapooja - 2018" />
          <Galleryinfo Img={Gal3} Name="PMP Meet @ Bangalore" />
          <Galleryinfo Img={Gal4} Name="RMS - Internal" />
          <Galleryinfo Img={Gal5} Name="TMS - Post Construction" />
          <div className="col-12 col-md-6 col-lg-4">
            <div className="photo">
              <img src={Gal6} alt="Gallery" className="img-fluid" />
              <h5 className="viewall">
                <span>Bulk Fumigation</span>
                <NavLink to="/gallery">
                  View All <BsArrowRight />
                </NavLink>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
