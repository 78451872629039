import React from "react";

const Title = ({ title, subTitle }) => {
  return (
    <>
      <span className="title_bar px-3 px-md-0">
        <span className="line"></span>
        <h5 className="title">{title}</h5>
      </span>
      <h2 className="sub_title px-3 px-md-0">{subTitle}</h2>
    </>
  );
};

export default Title;
