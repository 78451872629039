import React from "react";
import "./css/About.css";
import {
  FaCogs,
  FaHandHoldingUsd,
  FaHandsHelping,
  FaUsers,
  FaShippingFast,
} from "react-icons/fa";
import { GiProgression } from "react-icons/gi";
import { BsFillCalendar2WeekFill, BsPersonCheckFill } from "react-icons/bs";
import { Desktop, Tablet, Mobile } from "../View/View";
import Title from "./Title";

const About = () => {
  const Container = ({ view }) => {
    return (
      <div className={`about ${view} container`}>
        <Title title="About Us" subTitle="WHY SAI PEST CONTROL" />
        <div className="row px-3 px-md-0">
          <div className="col-12 col-lg-6">
            <p>
              We are started 2014 to introduce our self as one of the leading
              Pest Control Company in southern part of Tamil Nadu, having
              branches at Tuticorin, Rajapalaym, Madurai, Tirunelveli, Dindigul
              and Karur. SPC provides customized pest control service packages
              for Residential, Food Processing & Handling Industry,
              Pharmaceutical Industry, Healthcare Industry, Warehousing Industry
              and Hospitality Industry and more.
            </p>
          </div>
          <div className="col-12 col-lg-6">
            <p>
              SPC’s focuses on delivering high-quality, comprehensive cost
              effective solutions to attend and rectify our customer pest
              problems. Our services consist of thorough inspections,
              application, monitoring and recommendations on sanitation and
              proofing. Thanking you and assuring you our best attention at all
              times, we are.
            </p>
          </div>
        </div>
        <div className="row box_content">
          <div className="col-6 col-lg-3">
            <div className="box ">
              <div>
                <span>
                  <FaCogs />
                </span>
              </div>
              <h5>WELL EXECUTED SERVICES</h5>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box">
              <div>
                <span>
                  <FaHandHoldingUsd />
                </span>
              </div>
              <h5>ECONOMIC PRICES</h5>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box r">
              <div>
                <span>
                  <FaUsers />
                </span>
              </div>
              <h5>CLIENT CENTRIC ORIENTATION</h5>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box r">
              <div>
                <span>
                  <GiProgression />
                </span>
              </div>
              <h5>PROMPTNESS IN ENDEAVORS</h5>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box">
              <div>
                <span>
                  <FaHandsHelping />
                </span>
              </div>
              <h5>FLEXIBILITY IN APPROACH</h5>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box">
              <div>
                <span>
                  <FaShippingFast />
                </span>
              </div>
              <h5>FAST RESPONSE TIME TO EMERGENCY SITUATIONS</h5>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box r">
              <div>
                <span>
                  <BsFillCalendar2WeekFill />
                </span>
              </div>
              <h5>MONTHLY MAINTENANCE</h5>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box r">
              <div>
                <span>
                  <BsPersonCheckFill />
                </span>
              </div>
              <h5>ALL WORK CARRIED OUT WITH PERFECTION AND QUALITY</h5>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <Container view="" />
      </Desktop>
      <Tablet>
        <Container view="" />
      </Tablet>
      <Mobile>
        <Container view="sm" />
      </Mobile>
    </>
  );
};

export default About;
